import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import gettext from 'airborne/gettext';

import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Popover from 'react-bootstrap/lib/Popover';
import Loader from 'airborne/search2/Loader';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import getRateDetails from 'airborne/store/modules/search_hotels/actions/rateDetails';
import {getDetails} from 'airborne/search2/helpers/rates';

function nothing(event) {
    event.preventDefault();
}

function mapStateProps(state, {hotelId, rateKey}) {
    const {details, loading, loaded} = getDetails(state, hotelId, rateKey);

    if (!loaded) {
        return {loading};
    }
    const {
        'cancel_by': cancelBy,
        'cancellation_policy': cancelPolicy,
        'non_refundable': nonRefundable,
    } = details;

    return {
        loading,
        cancelBy,
        cancelPolicy,
        cancelPolicyKnown: true,
        nonRefundable,
    };
}

@connect(mapStateProps, {getRateDetails})
export default class CancelInfo extends React.Component {
    static propTypes = {
        hotelId: PropTypes.number.isRequired,
        rateKey: PropTypes.string.isRequired,
        loading: PropTypes.bool,
        nonRefundable: PropTypes.bool,
        cancelBy: PropTypes.string,
        cancelPolicy: PropTypes.string,
        cancelPolicyKnown: PropTypes.bool,
        getRateDetails: PropTypes.func.isRequired,
    };

    handleRetrieve = (event) => {
        nothing(event);
        const {rateKey, hotelId, loading, getRateDetails} = this.props;

        !loading && getRateDetails(hotelId, rateKey);
    }

    renderPopover(policy, loading) {
        const defaultPolicy = gettext('Cancellation policy is not available for this rate');
        return (
            <Popover id="cancel-policy" title={gettext('Cancellation info')} onClick={nothing} className="popover-fixed" >
                {loading ? (<Loader small/>) : (policy || defaultPolicy)}
            </Popover>
        );
    }

    renderButton(cancelBy, nonRefundable, known) {
        const className = classnames('h-rate__cxl__value', {
            'highlight-red': known && nonRefundable,
            'highlight-green': known && !nonRefundable,
            'text-primary nowrap border-none': !known,
        });
        if (!known) {
            return (
                <a href="#" className={className} onClick={this.handleRetrieve}>
                    <Glyphicon glyph="refresh" /> {gettext('Retrieve')}
                </a>
            );
        }

        return (
            <a href="#" className={className} onClick={nothing} >
                {nonRefundable
                    ? (<em>{gettext('Non‑refundable')}</em>)
                    : (cancelBy ? cancelBy : (<span className="btn btn-xs btn-link btn-link--narrow">{gettext('Show')}</span>))
                }
            </a>
        );
    }

    render() {
        const {cancelBy, cancelPolicy, cancelPolicyKnown, nonRefundable, loading} = this.props;
        return (
            <OverlayTrigger
                trigger="focus"
                placement="left"
                overlay={this.renderPopover(cancelPolicy, loading)} >
                {this.renderButton(cancelBy, nonRefundable, cancelPolicyKnown)}
            </OverlayTrigger>
        );
    }
}

import PropTypes from 'prop-types';
import React from 'react';
import gettext from 'airborne/gettext';
import classnames from 'classnames';
import settings from 'airborne/settings';
import markup from 'airborne/markup';
import {connect} from 'react-redux';

import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import HotelName from './HotelName';
import Price from './Price';
import PrefIcons from './PrefIcons';
import HotelTabsHeader from './HotelTabsHeader';
import Ratings from './Ratings';
import Photo from './Photo';
import Distance from './Distance';
import Promolines from './Promolines';
import ProviderErrors from './ProviderErrors';
import RateWarnings from './RateWarnings';
import OutOfPolicy from './OutOfPolicy';
import AnnotateTooltip from './AnnotateTooltip';
import formatAddress from '../helpers/address';
import HotelTabs from './HotelTabs';
import Item from 'midoffice/components/Item';

import normalize from 'airborne/search2/helpers/normalize';
import {loadAvail} from 'airborne/store/modules/search_hotels/actions/avail';
import {unselectHotel} from 'airborne/store/modules/search_hotels/actions/filters';
import {getAlternativeHotel} from 'airborne/search2/helpers/hotelSelector';
import {getHolelSelection, getHotelAvailabilityLoadings} from 'airborne/search2/helpers/hotels';

class HotelCard extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        loading: PropTypes.bool.isRequired,
        pin: PropTypes.bool.isRequired,
        showTrustYou: PropTypes.bool.isRequired,
        showCountry: PropTypes.bool.isRequired,
        selected: PropTypes.string,
        name: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        postalCode: PropTypes.string,
        stateProvinceCode: PropTypes.string,
        countryCode: PropTypes.string,
        address: PropTypes.string.isRequired,
        distance: PropTypes.number,
        direction: PropTypes.string,
        chainInfo: PropTypes.array,
        providerIds: PropTypes.object,
        trustyouRating: PropTypes.number,
        hasEcofriendlyBadge: PropTypes.bool,
        isFemaCompliant: PropTypes.bool,
        securityVetted: PropTypes.bool,
        hotelRating: PropTypes.number,
        clientPreferred: PropTypes.bool,
        clientPreferenceTier: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool,
        ]),
        clientPreferredByChain: PropTypes.bool,
        tmcPreferred: PropTypes.bool,
        tmcPreferenceTier: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.bool,
        ]),
        tmcPreferredByChain: PropTypes.bool,
        thumbnails: PropTypes.arrayOf(PropTypes.string),
        promoMessages: PropTypes.array,
        available: PropTypes.shape({
            isAvailable: PropTypes.bool,
            minAvgRate: PropTypes.number,
            currency: PropTypes.string,
            promoMessages: PropTypes.array,
        }),
        onSelect: PropTypes.func.isRequired,
        isCashOnlyHotel: PropTypes.bool.isRequired,
        isOnRequestHotel: PropTypes.bool.isRequired,
        showAlternative: PropTypes.bool.isRequired,
        isAlternative: PropTypes.bool,
        originalHotelName: PropTypes.string,
        searchPosition: PropTypes.number,
        labeling: PropTypes.object,
    };

    static defaultProps = {
        showAlternative: false,
        isAlternative: false,
        pin: false,
    };

    mode = settings.SUB_PARTNER;

    handleSelect = (tab)=> {
        const {selected} = this.props;
        this.props.onSelect(this.props.id, selected === tab ? null : tab);
    };

    handleToggle = ()=> {
        const {selected} = this.props;
        this.props.onSelect(this.props.id, selected ? null : 'rates');
    };

    renderHeadLabel() {
        return (
            <div className="hotel-card__head-label">
                <Glyphicon bsClass="glyphicons" glyph="money" />
                {markup(gettext('**This hotel may only accept cash.**'))}
            </div>
        );
    }

    renderBreakfast() {
        return (<div className="hotel-card__price__indicator">
            <AnnotateTooltip id="breakfast">
                <span className="icon-am-breakfast" />
                {gettext('Breakfast is included with the lowest rate')}
            </AnnotateTooltip>
            &nbsp;
            {gettext('incl.')}
        </div>);
    }

    renderPrice() {
        const {loading, available: {isAvailable, minAvgRate, currency, minRateBreakfastIncluded}={}, isFemaCompliant, labeling} = this.props;
        return (
            <Item price>
                {loading && !isAvailable
                    ? <div className="fade-in-out">{gettext('Loading…')}</div>
                    : (<Price className="h-item__price" min={minAvgRate} currency={currency} available={isAvailable} loading={loading} />)
                }
                {minRateBreakfastIncluded ? this.renderBreakfast() : null}

                {isFemaCompliant && labeling?.fema_compliant && <div className="h-item__price__label">
                    <div className="h-item__price__label--fema"/>
                    <div className="h-item__price__label--fema__text">{gettext('Compliant')}</div>
                </div>}
            </Item>
        );
    }

    renderPrefIcons() {
        const {
            clientPreferred, clientPreferenceTier,
            tmcPreferred, tmcPreferenceTier,
            clientPreferredByChain, tmcPreferredByChain,
            labeling, securityVetted,
            available: {
                outOfPolicy, outOfPolicyReason, currency,
            }={},
        } = this.props;

        return (
            <PrefIcons
                client={clientPreferred}
                clientTier={clientPreferenceTier}
                tmc={tmcPreferred}
                tmcTier={tmcPreferenceTier}
                clientByChain={clientPreferredByChain}
                tmcByChain={tmcPreferredByChain}
                securityVetted={securityVetted}
                labeling={labeling}
            >
                <OutOfPolicy icon reason={outOfPolicyReason} show={outOfPolicy && labeling?.['out_of_policy']} currency={currency} />
            </PrefIcons>
        );
    }

    render() {
        const {
            id,
            pin,
            showCountry,
            selected,
            name,
            address,
            city,
            postalCode,
            stateProvinceCode,
            countryCode,
            distance,
            direction,
            providerIds,
            chainInfo,
            trustyouRating,
            hasEcofriendlyBadge,
            thumbnails,
            hotelRating,
            promoMessages,
            showTrustYou,
            isCashOnlyHotel,
            isOnRequestHotel,
            isAlternative,
            originalHotelName,
            searchPosition,
            labeling,
            available: {
                promoMessages: availPromoMessages,
            }={},
        } = this.props;
        const cls = classnames('h-item', {
            'hotel-card--pinned': pin,
        });
        const showEcofriendly = hasEcofriendlyBadge && labeling?.ecofriendly;

        return (<div className={cls} data-alternative={isAlternative} data-original-hotel-name={originalHotelName}>
            <ProviderErrors id={id} />
            {isCashOnlyHotel && this.renderHeadLabel()}
            <div className="h-item__left-col">
                {this.renderPrice()}
                <Item hotel>
                    <div className="h-item__hotel-name" onClick={this.handleToggle}>
                        <Promolines promo={promoMessages || availPromoMessages} />
                        <HotelName
                            id={id}
                            name={name}
                            providerIds={providerIds}
                            showIds={this.mode === 'aft'}
                            chainInfo={chainInfo}
                            isOnRequest={isOnRequestHotel} />
                    </div>
                    <div className="h-item__hotel-addr">
                        {formatAddress(address, city, stateProvinceCode, postalCode, showCountry && countryCode)}
                    </div>
                </Item>

                {this.mode === 'aft' && <Item stars>
                    <Ratings stars={hotelRating} />
                </Item>}

                <Item distance>
                    <Glyphicon glyph="map-marker" />&nbsp;
                    <Distance distance={distance} direction={direction} />
                </Item>
                <footer className="h-item__footer">
                    <Item icons>
                        {this.renderPrefIcons()}
                    </Item>

                    <Item tabs>
                        <HotelTabsHeader
                            id={id}
                            selected={selected}
                            isAlternative={isAlternative}
                            onSelect={this.handleSelect} >
                            <RateWarnings id={id} />
                        </HotelTabsHeader>
                    </Item>
                </footer>
            </div>

            <div className="h-item__right-col">
                {this.mode === 'aft' && <Item rating> <Ratings
                    showEcoFilter={showEcofriendly}
                    showTrustYou={showTrustYou}
                    trustYou={trustyouRating}/>
                </Item>}
            </div>
            {selected && <HotelTabs selected={selected} id={this.props.id} searchPosition={searchPosition}/>}
        </div>);
    }
}

@connect((state, props) => ({
    altHotel: normalize(getAlternativeHotel(state, props)),
    selectedHotels: getHolelSelection(state),
    loadingAvail: getHotelAvailabilityLoadings(state),
    distanceUnits: settings.USER.distance_units,
}), {loadAvail, unselectHotel})
class HotelsWithAlternatives extends React.Component {
    static propTypes = {
        loadingAvail: PropTypes.object.isRequired,
        altHotel: PropTypes.object,
        selectedHotels: PropTypes.object.isRequired,
        showAlternative: PropTypes.bool,
        showTrustYou: PropTypes.bool,
        loading: PropTypes.bool,
        showCountry: PropTypes.bool,
        onSelect: PropTypes.func.isRequired,
        selected: PropTypes.string,
        loadAvail: PropTypes.func.isRequired,
        unselectHotel: PropTypes.func.isRequired,
        id: PropTypes.number.isRequired,
        distanceUnits: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    };

    render() {
        const {showTrustYou, loadingAvail, showCountry, onSelect, selectedHotels, distanceUnits, altHotel} = this.props;

        if (!altHotel) {
            return (<HotelCard {...this.props} />);
        }

        const displayDistance = {
            'MI': '1 mile',
            'KM': '1.6 km',
        };

        return (<>
            <header className="h-item__section-header">
                <div className="h-item__section-header__wrapper">
                    {gettext('You searched this property')}
                </div>
            </header>
            <HotelCard {...this.props} />

            <header className="h-item__section-header h-item__section-header--highlighted">
                <div className="h-item__section-header__wrapper">
                    <Glyphicon bsClass="glyphicons" glyph="lightbulb" />
                    {markup(gettext('Here\'s another option within **{distance}** that\'s also a **BCD preferred hotel**', {distance: displayDistance[distanceUnits]}))}
                </div>
            </header>
            <HotelCard
                {...altHotel}
                isAlternative
                originalHotelName={this.props.name}
                showCountry={showCountry}
                showTrustYou={showTrustYou}
                onSelect={onSelect}
                selected={selectedHotels[altHotel.id]}
                loading={loadingAvail[altHotel.id] || false}
            />
        </>);
    }
}


export default class HotelCardWrapper extends React.Component {
    static propTypes = {
        canShowAlternativeHotel: PropTypes.bool,
        tmcPreferred: PropTypes.bool.isRequired,
        available: PropTypes.object,
        searchPosition: PropTypes.number,
    };

    shouldRenderAlternativeHotel() {
        const {canShowAlternativeHotel, available: {isAvailable, outOfPolicy}={}, tmcPreferred} = this.props;
        return canShowAlternativeHotel && (
            //TODO: Move this contition to helper
            !isAvailable || !tmcPreferred || outOfPolicy
        );
    }

    render() {
        return this.shouldRenderAlternativeHotel()
            ? <HotelsWithAlternatives {...this.props} />
            : <HotelCard {...this.props} />;
    }
}

import gettext from 'airborne/gettext';
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {toMap, toList} from 'airborne/store/modules/search_hotels/actions';

import Button from 'react-bootstrap/lib/Button';

@connect(null, {onMap: toMap, onList: toList})
export default class Switch extends React.Component {
    static propTypes = {
        asMap: PropTypes.bool,
        onMap: PropTypes.func.isRequired,
        onList: PropTypes.func.isRequired,
    };

    render() {
        return (<div className="map-trigger-lnk">
            <Button bsStyle="link" onClick={this.props.onMap} />
            <span className="map-trigger-lnk-text">{gettext('View on Map')}</span>
        </div>);
    }
}

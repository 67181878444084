'process i18n';

import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import classnames from 'classnames';
import gettext from 'airborne/gettext';
import ngettext from 'airborne/gettext';

import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Button from 'react-bootstrap/lib/Button';
import OfferDetails from './hotel/OfferDetails';

import EmailOffersModal from './EmailOffersModal';

import {mapEmailOffers} from 'airborne/search2/helpers/offers';
import {removeOffer, sendOffers, clearOffers} from 'airborne/store/modules/search_hotels/actions/offers';

import {format as formatPrice} from 'airborne/utils/price';

import {copyHotelOffers, copyHotelOfferInfo} from 'airborne/search2/helpers/copyToClipboard';
import {showQuickAlert} from 'airborne/store/modules/homepage/actions';
import CopyToClipboardTooltip from 'airborne/search2/hotel/CopyToClipboardTooltip';

import settings from 'airborne/settings';


class Offer extends React.Component {
    static propTypes = {
        hotel: PropTypes.object.isRequired,
        rate: PropTypes.object.isRequired,
        onRemove: PropTypes.func.isRequired,
    };

    state = {details: false};

    handleRemove = ()=> {
        const {hotel, rate, onRemove} = this.props;
        onRemove(hotel.id, rate.rateKey);
    }

    handleDetails = (event)=> {
        event.preventDefault();
        this.setState({details: true});
    }

    handleHideDetails = ()=> {
        this.setState({details: false});
    }

    handleCopyToClipboard = () => {
        const {hotel, rate} = this.props;
        copyHotelOfferInfo(hotel, rate);
    }

    render() {
        const {hotel, rate} = this.props;
        const {details} = this.state;
        const {average, currency, rateKey, ratePromoline, rateDescription} = rate;

        return (
            <div className="float-panel__item offer">
                <div className="float-panel__item__close close" onClick={this.handleRemove}>
                    <Glyphicon glyph="remove" />
                </div>
                <div className="float-panel__item__price">
                    {formatPrice(average, currency)}
                    <CopyToClipboardTooltip onClick={this.handleCopyToClipboard} />
                </div>
                <div className="float-panel__item__content">
                    <div className="float-panel__item__title">
                        {hotel.name}
                    </div>

                    <div className="float-panel__item__row">
                        <h5>{ratePromoline}</h5>
                    </div>

                    <div className="float-panel__item__row">
                        <small className="float-panel__item__small">{rateDescription}</small>
                    </div>

                    <div className="float-panel__item__row">
                        <a href="#" className="show-rate-details" onClick={this.handleDetails}>{gettext('Details')} &raquo;</a>
                    </div>
                </div>

                {details && (
                    <OfferDetails
                        hotelId={hotel.id}
                        rateKey={rateKey}
                        currency={currency}
                        onHide={this.handleHideDetails} />
                )}
            </div>
        );
    }
}


export class ControlledEmailOffers extends React.Component {
    static propTypes = {
        offers: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired,
        total: PropTypes.number.isRequired,
        removeOffer: PropTypes.func.isRequired,
        sendOffers: PropTypes.func.isRequired,
        clearOffers: PropTypes.func.isRequired,
        show: PropTypes.bool.isRequired,
        showQuickAlert: PropTypes.func,
    };

    state = {minimized: true, modal: false, clicked: false};

    handleHeaderClick = ()=> {
        this.setState({minimized: !this.state.minimized});
    }

    handleShowModal = ()=> {
        this.setState({modal: true});
    }

    handleHideModal = ()=> {
        this.setState({modal: false});
    }

    handleCopyAllOffers = () => {
        const {offers, showQuickAlert} = this.props;

        if (this.state.clicked) {
            return;
        }

        copyHotelOffers(offers);
        showQuickAlert(gettext('Successfully copied to clipboard.'));

        this.setState({clicked: true});
        setTimeout(() => this.setState({clicked: false}), 2000);
    }

    renderModal(offers, loading) {
        const {sendOffers} = this.props;
        const value = {
            'sender': '',
            'cc': [],
            'bcc': [],
            'custom_text': '',
        };
        return (
            <EmailOffersModal
                value={value}
                loading={loading}
                offers={offers}
                onSubmit={sendOffers}
                onHide={this.handleHideModal} />
        );
    }

    render() {
        const {offers, loading, total, removeOffer, clearOffers, show} = this.props;
        const {minimized, modal, clicked} = this.state;

        const className = classnames('float-panel', {
            'float-panel--minimized': minimized,
        });

        if (!total || !show) { return null; }

        return (
            <div className={className}>
                <div className="offers-header float-panel__header" onClick={this.handleHeaderClick}>
                    <h4 className="float-panel__title">{gettext('Email Offers')}</h4>
                    <aside className="float-panel__aside">{ngettext('{num} rate added', '{num} rates added', total)}</aside>
                </div>
                <div className="float-panel__footer">
                    <Button bsStyle="primary" name="send" onClick={this.handleShowModal}>
                        {gettext('Send Offers')}
                    </Button>
                    <Button name="close" className="pull-left" onClick={clearOffers}>{gettext('Close')}</Button>
                    <div className="col">
                        <Button bsStyle="default" className="pull-left" onClick={this.handleCopyAllOffers} disabled={clicked}>
                            {gettext('Copy all to clipboard')} {clicked && <Glyphicon glyph='ok' />}
                        </Button>
                    </div>
                </div>
                <div className="float-panel__body">
                    {offers.map((offer)=> (
                        <Offer
                            {...offer}
                            onRemove={removeOffer}
                            key={[offer.hotel.id, offer.rate.rateKey]} />
                    ))}
                </div>

                {modal && this.renderModal(offers, loading)}
            </div>
        );
    }
}

const EmailOffers = connect(mapEmailOffers, {removeOffer, sendOffers, clearOffers, showQuickAlert})(ControlledEmailOffers);
export default EmailOffers;

import some from 'lodash/some';
import gettext from 'airborne/gettext';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import AnnotateTooltip from './AnnotateTooltip';

function hasTimeout(warnings) {
    return warnings && some(warnings.map(({type})=> type === 'ETIMEOUT'));
}

function hasExclusions(warnings) {
    return warnings && some(warnings.map(({type})=> type === 'AllRatesExcludedByRateExclusion'));
}

function mapStateProps({hotels: {rateWarnings}}, {id}) {
    return {
        warnings: rateWarnings[id] || null,
    };
}

@connect(mapStateProps)
export default class RateWarnings extends React.Component {
    static propTypes = {
        warnings: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
        })),
    };

    renderSign(message) {
        return (<div className="hotel-card__warning">
            <AnnotateTooltip id="missing_rates">
                <Glyphicon glyph="exclamation-sign" />
                {message}
            </AnnotateTooltip>
        </div>);

    }

    render() {
        const {warnings} = this.props;
        if (hasTimeout(warnings)) {
            return  this.renderSign(gettext('Some third-party search partners are currently experiencing technical difficulties. Certain rates may be absent in search results.'));
        }
        if (hasExclusions(warnings)) {
            return  this.renderSign(gettext('Some rates were excluded by your administrator.'));
        }
        return null;
    }
}


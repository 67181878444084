import CancellationPolicyList from 'airborne/checkout2/CancellationPolicy';

'process i18n';;
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import gettext from 'airborne/gettext';

import Loader from 'airborne/search2/Loader';
import Modal from 'react-bootstrap/lib/Modal';

import {format as formatPrice} from 'airborne/utils/price';
import {providerCode} from 'midoffice/helpers/format';
import getRateDetails from 'airborne/store/modules/search_hotels/actions/rateDetails';
import {removeOffer} from 'airborne/store/modules/search_hotels/actions/offers';
import {mapEmailOffer} from '../helpers/offers';


const BreakLine = ()=> (<div><br/></div>);


export class ControlledOfferDetails extends React.Component {
    static propTypes = {
        hotelId: PropTypes.number.isRequired,
        rateKey: PropTypes.string.isRequired,
        offer: PropTypes.object.isRequired,
        labeling: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onHide: PropTypes.func.isRequired,
        removeOffer: PropTypes.func.isRequired,
        getRateDetails: PropTypes.func.isRequired,
    };

    handleRemove = (event)=> {
        event.preventDefault();
        const {removeOffer, rateKey, hotelId} = this.props;
        return removeOffer(hotelId, rateKey);
    };

    renderHotel(hotel) {
        return (
            <div>
                <h2 className="modal-rate-desc__title">
                    {hotel.name}
                </h2>
                {hotel.address}&nbsp;{hotel.city}
            </div>
        );
    }

    renderRate({average, currency, ratePromoline, rateDescription, provider, paidPolicy, commission}, {cancellationPolicy}, labeling) {
        const commissionDetails = commission.commissionable ? gettext('Commissionable') : gettext('Non-commissionable');
        return (
            <div>
                <h2 className="modal-rate-desc__title">
                    {ratePromoline}
                </h2>
                {rateDescription}

                <BreakLine />
                <div>{formatPrice(average, currency)}&nbsp;{gettext('avg. / night')}</div>
                {labeling.supplier && (<div>
                    {gettext('Provider')}:&nbsp;
                    <span className="hotel-rate__source">
                        {providerCode(provider)}
                    </span>
                </div>)}
                <div>{gettext('Guarantee/Deposit')}:&nbsp;{paidPolicy}</div>

                <BreakLine />

                {cancellationPolicy && (<div>
                    {gettext('Cancelation policy')}:<br/>
                    <CancellationPolicyList cancellationPolicy={cancellationPolicy} />
                </div>)}

                <BreakLine />

                <div>{gettext('Commission details')}:&nbsp;{commissionDetails}</div>
            </div>
        );
    }

    render() {
        const {offer: {hotel, rate, details}, loading, labeling, onHide} = this.props;

        return (
            <Modal show onHide={onHide} >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {gettext('Offer Details')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <Loader /> : (<div>
                        {this.renderHotel(hotel)}
                        {this.renderRate(rate, details, labeling)}
                    </div>)}
                </Modal.Body>
                <Modal.Footer className="clearfix">
                    <div className="pull-left">
                        <a href="#" className="remove-offer" onClick={this.handleRemove}>{gettext('Remove offer')}</a>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

const OfferDetails = connect(mapEmailOffer, {removeOffer, getRateDetails})(ControlledOfferDetails);
export default OfferDetails;

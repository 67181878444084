import React from 'react';
import MultiCheckbox from 'midoffice/newforms/widgets/MultiCheckbox';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import {gettext} from 'airborne/gettext';
import Button from 'midoffice/components/Button';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getFilters} from 'airborne/search2/helpers/rates';
import {filterRates} from 'airborne/store/modules/search_hotels/actions/rates';
import amenities from 'airborne/search2/amenities';
import {FILTERABLE_AMENITIES} from 'midoffice/helpers/paginate';

const amenitiesRateTypes = FILTERABLE_AMENITIES.map((code) =>
    [code, amenities(code), null, gettext('Amenities:')]
);

const RATE_TYPES /* type: [name, label, description, group] */ = [
    ['rate_filter', gettext('Guarantee Only'), null, gettext('Rate Type:')],
    ...amenitiesRateTypes,
    ['non_refundable', gettext('Exclude Non-Refundable'), null, gettext('Cancelation policy:')],
];

@connect((state, props) => ({
    filters: getFilters(state, props.id),
}), {filterRates})
export default class RateFiltersMenu extends React.Component {
    static propTypes = {
        id: PropTypes.number,
        filterRates: PropTypes.func,
        filters: PropTypes.object,
    };
    constructor(props) {
        super(props);
        const {filters: {rateFilter}} = props;

        this.state = {//eslint-disable-line immutable/no-mutation
            rateFilter: rateFilter || [],
            isOpenRateFilters: false,
        };
    }
    applyFilters = () => {
        const filters = {
            rateFilter: this.state.rateFilter
        };
        this.props.filterRates(this.props.id, filters);
        this.handleToggleRateFiltersMenu();
    }

    handleToggleRateFiltersMenu = () => {
        const {filters: {rateFilter}} = this.props;
        this.setState({isOpenRateFilters: !this.state.isOpenRateFilters, rateFilter: rateFilter || []});
    }
    handleChangeRateTypes = (filterArray) => {
        this.setState({rateFilter: filterArray});
    }

    renderFilterMenu = () => {
        const {rateFilter} = this.state;
        return (<div className={'dropdown-menu dropdown-menu--rates-filter'} style={{display: 'block'}}>
            <h3 className="dropdown-menu__header">{gettext('Rate Filters')}</h3>

            <div className="rate-filter__wrapper">
                <Row>
                    <Col xs={12} >
                        <div className="rate-filter__item">
                            <MultiCheckbox
                                name="rate_type"
                                label="Rate type"
                                value={rateFilter}
                                groups
                                table={false}
                                border={false}
                                className={'filter_rate'}
                                onChange={this.handleChangeRateTypes}
                                choices={RATE_TYPES}/>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="rate-filter__footer">
                <Button onClick={this.applyFilters} className="apply-btn btn-secondary">{gettext('Apply')}</Button>
                <Button bsStyle="link" onClick={this.handleToggleRateFiltersMenu}>{gettext('Cancel')}</Button>
            </div>
        </div>);
    }
    render() {
        return (<div className="dropdown pull-right">
            <Button className={'open-filter dropdown-toggle'}
                bsSize="xsmall"
                onClick={this.handleToggleRateFiltersMenu}> {gettext('Rate Filters')}
                <span className="caret" />
            </Button>
            {this.state.isOpenRateFilters && this.renderFilterMenu()}
        </div>);
    }
}

/* global module */
import React from 'react';
import PropTypes from 'prop-types';
import {hot} from 'react-hot-loader';

import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import DestinationSearch from 'airborne/homepage2/DestinationSearch';

import Hotels from './Hotels';
import Map3 from './maploader3';
import MultiSearchContext from './MultiSearchContext';
import SearchFilters from './SearchFilters';
import EmailOffers from './EmailOffers';
import Switch from './map3/Switch';
import MultiBookFooter from './MultiBookFooter';
import HomepageButton from './HomepageButton';
import RateTarget from './RateTarget';

import WaitBooking from 'airborne/search2/WaitBooking';

import settings from 'airborne/settings';

import '../../../midoffice/less/bootstrap-search.less';
import Ads from 'airborne/search2/Ads';

@hot(module)
export default class Search extends React.Component {
    static propTypes = {
        asMap: PropTypes.bool,
    };

    render() {
        const {asMap} = this.props;
        const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

        return (
            <div>
                <DestinationSearch results editable />
                <MultiBookFooter />
                <div className="container clearfix">
                    <Row>
                        <Col xs={3} className="rev-sidebar">
                            <HomepageButton />
                            {settings.SUB_PARTNER !== 'aft' && (
                                <RateTarget />
                            )}
                            {settings.SUB_PARTNER === 'aft' && (
                                <MultiSearchContext />
                            )}
                            <div className="section-wrapper">
                                {!isIE11 && <Switch asMap={asMap}/>}
                                <hr />
                                <SearchFilters />
                            </div>
                        </Col>
                        <Col xs={9} className="rev-content">
                            <div className="section-hotels">
                                <Hotels />
                            </div>
                            <div className="c-content__inline-a c-content__inline-a--content-footer">
                                <div className="c-content__inline-a__item">
                                    <Ads id="81876" />
                                </div>
                                <div className="c-content__inline-a__item">
                                    <Ads id="81877" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                {asMap && <Map3 />}
                <EmailOffers />
                <WaitBooking />
            </div>
        );
    }
}
